<template>
  <div id="navbar">
    <!-- NAVBAR -->
    <nav class="jnavbar jpadding-nav">
      <!-- navbar area left -->
      <div class="nav-left">
        <!-- NAVBAR TOGGLE BTN -->
        <div class="toggle-container">
          <div
            :class="{ active: openOverlay }"
            class="toggle-button"
            id="toggle"
            @click="openOverlay = !openOverlay"
          >
            <!-- <span class="top"></span> -->
            <span class="middle"></span>
            <span class="bottom"></span>
          </div>
        </div>
        <div class="navbar-title brand uppercase">
          <!-- <?php echo $lang["navbar_brand"] ?> -->
        </div>
        <div class="navbar-title brand-catalog uppercase">
          <!-- <?php echo $lang["navbar_catalog_model"] ?><?php echo $modelData["name"] ?> -->
        </div>
        <div class="navbar-title catalog uppercase">
          <!-- <?php echo $lang["navbar_catalog"] ?> -->
        </div>
      </div>

      <!-- logo -->
      <!-- <div class="nav-brand"> -->
      <router-link
        v-if="userIsLoggedIn"
        :to="userIsLoggedIn ? { name: getDashboardRouteName() } : { path: '/' }"
        class="nav-brand"
      >
        <img src="@/assets/logo/duke-club-logo-white.png" alt="Duke Club" />
      </router-link>
      <router-link v-else :to="{ path: '/' }" class="nav-brand">
        <img src="@/assets/logo/duke-logo-white.png" alt="Duke Club" />
      </router-link>
      <!-- </div> -->

      <!-- navbar area right -->
      <div class="nav-right" v-if="userIsLoggedIn">
        <!-- <router-link :to="{ name: 'MyWatches' }" class="jnav-link hide-m"
          ><i class="fal fa-watch"></i
        ></router-link>
        <router-link :to="{ name: 'AppointmentsList' }" class="jnav-link hide-m"
          ><i class="fal fa-clock"></i
        ></router-link>
        <router-link
          :is="!profileRouteName ? 'span' : 'router-link'"
          :to="{ name: getProfileRouteName() }"
          class="jnav-link hide-m"
          ><i class="fal fa-user"></i> Profile</router-link
        > -->
        <!-- <div class="nav-right wht jnav-link" @click="logout"><i class="fal fa-sign-out"></i>&nbsp;Logout</div> -->

        <b-dropdown
          v-if="userIsLoggedIn"
          id="dropdownMenu"
          right
          variant="link"
          toggle-class="jnav-link dropdown-toggle"
          no-caret
        >
          <template #button-content>
            <i class="fal fa-user"></i> <span class="hide-m">{{ user.lastName }}</span>
            <i class="fal fa-chevron-down"></i>
          </template>
          <b-dropdown-item :to="{ name: 'DashboardOwner' }"
            ><i class="fal fa-th-large mr-2"></i> Dashboard
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            :is="!profileRouteName ? 'span' : 'b-dropdown-item'"
            :to="{ name: getProfileRouteName() }"
            ><i class="fal fa-cog mr-2"></i> Profile
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'MyWatches' }"
            ><i class="fal fa-watch mr-2"></i> My Watches
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'AppointmentsList' }"
          ><i class="fal fa-clock mr-2"></i> Appointments
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'Collection' }"
          ><i class="fal fa-shopping-cart mr-2"></i> Shop
        </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout"><i class="fal fa-sign-out mr-2"></i> Logout </b-dropdown-item>
          <!-- <b-dropdown-item @click="deleteService(index)"><i class="fal fa-trash mr-2"></i> Delete
                    appointment</b-dropdown-item> -->
        </b-dropdown>

        <!-- <div class="dropdown" id="dropdownUser">
          <a
            class="jnav-link dropdown-toggle hide-s"
            href="#"
            data-toggle="dropdown"
            role="button"
            aria-expanded="false"
          >English<i class="fal fa-chevron-down"></i
          ></a>
          <a
            class="jnav-link dropdown-toggle show-s"
            href="#"
            data-toggle="dropdown"
            role="button"
            aria-expanded="false"
          >
            En <i class="fal fa-chevron-down"></i
          ></a>
          <div class="dropdown-menu dropdown-menu-right sm-dropdown" aria-labelledby="dropdownLang">
            <a class="dropdown-item active" href="">EN</a>
            <a class="dropdown-item" href="">FR</a>
          </div>
        </div>-->
      </div>

      <!-- NAVBAR OVERLAY -->
      <div :class="{ open: openOverlay }" class="overlay" id="overlay">
        <div class="overlay-container duke-nav-style">
          <div class="overlay-top">
            Menu
          </div>
          <nav class="overlay-content">
            <div class="overlay-main">
              <a href="https://dukehorlogerie.com">Home</a>

              <a href="https://club.dukehorlogerie.com/collection">Collection</a>

              <a href="https://dukehorlogerie.com/brand">Brand</a>

              <router-link :to="{ name: getDashboardRouteName() }">Club</router-link>

              <a v-if="userIsLoggedIn" href="https://club.dukehorlogerie.com/contact">Contact</a>
              <a v-if="!userIsLoggedIn" href="https://dukehorlogerie.com/contact">Contact</a>
            </div>
            <div class="navbar-bottom">
              <!-- <a href="/club" class="jnav-link" data-aos=" fade-up"></i><?php echo $lang["log_in"] ?></a> -->
              <a href="https://dukehorlogerie.com/privacy" class="jnav-link">Privacy</a>
              <!-- <a href="/signup" class="jnav-link" data-aos=" fade-up"></i><?php echo $lang["register"] ?></a> -->
              <a href="https://dukehorlogerie.com/terms" class="jnav-link">Terms</a>
            </div>
            <div class="navbar-bottom">
              <div class="bottom-links-box jnav-socials">
                <a href="https://www.facebook.com/dukehorlogerie/" target="_blank" class="jnav-link"
                  ><i class="fa fa-facebook"></i
                ></a>
                <a href="https://instagram.com/dukehorlogerie" target="_blank" class="jnav-link"
                  ><i class="fa fa-instagram"></i
                ></a>
                <a href="tel:+352671204060" class="jnav-link"><i class="fa fa-whatsapp"></i></a>
                <a href="tel:+352671204060" class="jnav-link"><i class="fa fa-wechat"></i></a>
              </div>
              <!-- <div class="bottom-links-box jnav-lang">
                <a href="/en/" class="jnav-link">EN</a>
                <a href="/fr/" class="jnav-link">FR</a>
              </div> -->
            </div>
          </nav>
        </div>
        <div class="overlay-background" @click="openOverlay = !openOverlay"></div>
      </div>
    </nav>
  </div>
</template>

<script>
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;

export default {
  data: () => ({
    openOverlay: false,
    profileRouteName: null,
    dashboardRouteName: null,
    userIsLoggedIn: auth.currentUser ? true : false
  }),
  methods: {
    logout() {
      this.$store.commit("setNewRoute", "Login");
      auth.signOut().then(() => {
        this.$store.commit("finishProcess");
      });
    },
    getProfileRouteName() {
      const type = this.$store.getters.getUserTypeString;
      if (type) {
        this.profileRouteName = "Profile" + type;
      } else {
        this.handleClaimsError();
      }

      return this.profileRouteName;
    },
    getDashboardRouteName() {
      if (!auth.currentUser) return "Login";

      const type = this.$store.getters.getUserTypeString;
      if (type) {
        if (type === C.TYPE_INDEX[C.TYPE.ADMIN]) {
          var routeString = this.$router.currentRoute.path;
          if (routeString.includes("/collection")) {
            // special case: need to check history (not always reliable though)
            routeString = this.$router.history._startLocation;
          }

          if (routeString.includes("/owner/")) {
            this.dashboardRouteName = "DashboardOwner";
          } else if (routeString.includes("/member/")) {
            this.dashboardRouteName = "DashboardMember";
          } else if (routeString.includes("/press/")) {
            this.dashboardRouteName = "DashboardPress";
          } else {
            this.dashboardRouteName = "DashboardAdmin";
          }
        } else this.dashboardRouteName = "Dashboard" + type;
      } else {
        this.handleClaimsError();
      }

      return this.dashboardRouteName;
    },

    getHomeName() {
      if (!auth.currentUser) return "";

      const type = this.$store.getters.getUserTypeString;
      if (type) {
        this.dashboardRouteName = "Dashboard" + type;
      } else {
        this.handleClaimsError();
      }

      return this.dashboardRouteName;
    },
    handleClaimsError() {
      // TODO: maybe log user out instead, so that he can login again and refresh token?
      // BUT: this happens as default while we are waiting for claims to be loaded...
      // => either add more complicated logic to authstore with callbacks when claims are ready
      // => or just wait and trust on router + firebase rules to restrict any unnecessary access
      // console.log(error);
      this.profileRouteName = "#";
      this.dashboardRouteName = "#";
    }
  },
  computed: {
    loggedIn: {
      get() {
        return this.$store.getters.getAuth;
      }
    },
    getDas: {
      get() {
        const startRoutePath = this.$router.history._startLocation;
        if (startRoutePath === "/owner/") {
          return "Owner";
        }
        console.log("ROUTE HISTO:");
        console.log();
        return this.$route.matched[0].name;
      }
    },
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    }
  },
  beforeCreate() {},
  created() {
    if (!auth.currentUser)
      return;
      
    //init
    this.userId = auth.currentUser.uid;

    //load user
    var userDocRef = db.collection(C.COLLECTION.USERS).doc(this.userId);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function(error) {
        console.log("Error getting USER document:", error);
      });
  }
};
</script>

<style scoped></style>
