import Vue from "vue";
import firebase from "firebase/app";
import VueRouter from "vue-router";
import Authstore from "@/store/store.js";
//views:
import Layout from "@/views/Layout.vue";
import Login from "@/views/Login.vue";
// import Register from "@/views/Register.vue";
import C from "@/const";
const auth = firebase.auth();
//dev mode
var authMode = true;

Vue.use(VueRouter);

/**
 * ROUTING
 */
const routes = [
  /**
   * PUBLIC PAGES
   */
  {
    name: "Login",
    path: C.URL_PREFIX + "/login/:type?",
    component: Login,
    meta: { requiresAuth: false }
  },
  // {
  //   name: "Register",
  //   path: "/register",
  //   component: Register,
  //   meta: { requiresAuth: false }
  // },
  {
    name: "MemberAccountCreate",
    path: C.URL_PREFIX + "/register/member",
    component: () => import("@/components/public/MemberAccountCreate"),
    meta: { requiresAuth: false }
  },
  {
    name: "PressAccountCreate",
    path: C.URL_PREFIX + "/register/press",
    component: () => import("@/components/public/PressAccountCreate"),
    meta: { requiresAuth: false }
  },
  /**
   * ===================================
   * CLUB PAGES REQUIRUNG AUTHENTICATION
   * ===================================
   */

  /**
   * Collection/Store/Shop + Redirection
   */
  {
    name: "Collection",
    path: C.URL_PREFIX + "/collection",
    component: () => import("@/components/public/Collection"),
    meta: {
      requiresAuth: false,
      memberAccess: true,
      ownerAccess: true,
      pressAccess: true
    },

    children: [
      {
        name: "Redirection",
        path: "redirection",
        component: () => import("@/components/public/WatchRedirection")
      }
    ]
  },

  {
    name: "WatchRedirection",
    path: C.URL_PREFIX + "/redirection",
    component: () => import("@/components/public/WatchRedirection"),
    meta: {
      requiresAuth: true,
      memberAccess: true,
      ownerAccess: true,
      pressAccess: false
    }
  },

  /**
   * Member pages
   */
  {
    path: C.URL_PREFIX + "/member",
    component: Layout,
    meta: {
      requiresAuth: authMode,
      memberAccess: authMode
    },

    children: [
      {
        name: "DashboardMember",
        path: "/",
        component: () => import("@/components/member/DashboardMember")
      },

      // same profile as owner (only difference: url & user type)
      {
        name: "ProfileMember",
        path: "profile",
        component: () => import("@/components/Profile.vue")
      },
      // same payments as owner (different url)
      // TODO: adapt payments to member => adapt filter or just leave as-is
      {
        name: "PaymentsMember",
        path: "payments",
        component: () => import("@/components/owner/Payments.vue")
      },
      // same news as owner (different url and filter)
      // TODO: adapt news to member => different query (= filter)
      {
        name: "NewsMember",
        path: "news",
        component: () => import("@/components/owner/News.vue")
      }
    ]
  },
  /**
   * Owner pages
   */
  {
    path: C.URL_PREFIX + "/owner",
    component: Layout,
    meta: {
      requiresAuth: authMode,
      ownerAccess: authMode
    },

    children: [
      {
        name: "DashboardOwner",
        path: "/",
        component: () => import("@/components/owner/DashboardOwner")
      },
      {
        name: "AppointmentRedirection",
        path: "redirection/:appointmentId",
        component: () => import("@/components/public/AppointmentRedirection")
      },
      {
        name: "PaymentFailed",
        path: "payment-failed",
        component: () => import("@/components/public/PaymentFailed")
      },
      {
        name: "MyWatches",
        path: "watches",
        component: () => import("@/components/owner/WatchesHome")
      },
      {
        name: "MyWatch",
        path: "watch/:watchId",
        component: () => import("@/components/owner/Watch")
      },
      {
        name: "Certificate",
        path: "certificate/:id?",
        component: () => import("@/components/owner/Certificate")
      },
      {
        name: "Warranty",
        path: "warranty/:id?",
        component: () => import("@/components/owner/watch-card/Warranty")
      },
      {
        name: "Tutorials",
        path: "tutorials/:id?",
        component: () => import("@/components/owner/watch-card/Tutorials")
      },
      {
        name: "ServiceHistory",
        path: "service-history/:id?",
        component: () => import("@/components/owner/watch-card/ServiceHistory")
      },
      //@Deprecated
      // {
      //   name: "ServiceMain",
      //   path: "watch/:watchId/service",
      //   component: () => import("@/components/owner/ServiceMain")
      // },
      {
        name: "ServicesList",
        path: "watch/:watchId/services",
        component: () => import("@/components/owner/ServicesList")
      },
      {
        name: "ServiceCard",
        path: "watch/:watchId/service/:serviceId",
        component: () => import("@/components/owner/ServiceCard")
      },
      {
        name: "Appointment",
        path: "watch/:watchId/service/:serviceId/appointment",
        component: () => import("@/components/owner/Appointment")
      },
      //@Deprecated
      // {
      //   name: "AppointmentSetup",
      //   path: "setup/appointment",
      //   component: () => import("@/components/owner/AppointmentSetup")
      // },
      {
        name: "AppointmentsList",
        path: "appointments",
        component: () => import("@/components/owner/AppointmentsList")
      },
      {
        name: "PaymentsOwner",
        path: "payments/:id?",
        component: () => import("@/components/owner/Payments.vue")
      },
      // same profile as member (only difference: url & user type)
      {
        name: "ProfileOwner",
        path: "profile",
        component: () => import("@/components/Profile.vue")
      },
      {
        name: "AppointmentCardOwner",
        path: "appointmentCard/:appointmentId",
        component: () => import("@/components/owner/AppointmentCardOwner")
      },
      {
        name: "NewsOwner",
        path: "news",
        component: () => import("@/components/owner/News")
      },
      {
        name: "Contact",
        path: "support",
        component: () => import("@/components/owner/Contact")
      }
    ]
  },
  /**
   * Press pages
   */
  {
    path: C.URL_PREFIX + "/press",
    component: Layout,
    meta: {
      requiresAuth: authMode,
      pressAccess: authMode
    },

    children: [
      {
        name: "DashboardPress",
        path: "/",
        component: () => import("@/components/press/DashboardPress")
      },
      {
        name: "ProfilePress",
        path: "profile",
        component: () => import("@/components/Profile.vue")
      },
      // TODO: use a different folder for news component
      {
        name: "NewsPress",
        path: "news",
        component: () => import("@/components/owner/News")
      },
      {
        name: "PressKits",
        path: "press-kits",
        component: () => import("@/components/press/PressKits")
      },
    ]
  },
  /**
   * Admin pages
   */
  {
    path: C.URL_PREFIX + "/admin",
    component: () => import("@/views/AdminLayout.vue"),
    meta: {
      requiresAuth: authMode,
      adminAccess: authMode
    },
    children: [
      {
        name: "DashboardAdmin",
        path: "",
        component: () => import("@/components/admin/DashboardAdmin")
      },
      {
        name: "ProfileAdmin",
        path: "profile",
        component: () => import("@/components/Profile.vue")
      },
      {
        name: "UsersList",
        path: "users",
        component: () => import("@/components/admin/UsersList")
      },
      {
        name: "VerifyPress",
        path: "verify-press",
        component: () => import("@/components/admin/UsersVerifyPress")
      },
      {
        name: "CreateUser",
        path: "create-user",
        component: () => import("@/components/admin/UserCreate")
      },
      {
        name: "UserAccount",
        path: "user/:id?",
        component: () => import("@/components/admin/UserAccount")
      },
      {
        name: "Password",
        path: "password",
        component: () => import("@/components/user/Security")
      },
      {
        name: "WatchCatalog",
        path: "watch-catalog",
        component: () => import("@/components/admin/WatchCatalog")
      },
      {
        name: "CreateWatchModel",
        path: "create-watch-model/:id?",
        component: () => import("@/components/admin/WatchModelCreate")
      },
      {
        name: "WatchModelUploads",
        path: "watch-model-uploads/:id?",
        component: () => import("@/components/admin/WatchModelUploads")
      },
      {
        name: "WatchesList",
        path: "watches",
        component: () => import("@/components/admin/WatchesList")
      },
      {
        name: "CreateWatch",
        path: "create-watch",
        component: () => import("@/components/admin/WatchCreate")
      },
      {
        name: "Watch",
        path: "watch/:id?",
        component: () => import("@/components/admin/Watch")
      },
      {
        name: "AppointmentsAdmin",
        path: "appointments",
        component: () => import("@/components/admin/AppointmentsList")
      },
      {
        name: "AppointmentCardAdmin",
        path: "appointment/:id?",
        component: () => import("@/components/admin/AppointmentCard")
      },
      {
        name: "ServiceCatalogue",
        path: "service-catalogue",
        component: () => import("@/components/admin/ServiceCatalogue")
      },
      {
        name: "Notifications",
        path: "notifications",
        component: () => import("@/components/admin/Notifications")
      },
      {
        name: "News",
        path: "news",
        component: () => import("@/components/admin/News")
      },
      {
        name: "CreateNews",
        path: "create-news/:id?",
        component: () => import("@/components/admin/NewsCreate")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

/**
 * ACCESS CONTROL
 */

// make sure only logged-in users have access to the content while only guests(+not signed-in users) see register & login
router.beforeEach((to, from, next) => {
  // console.log("NEXT: ");
  // console.log(to);
  // console.log("FROM: ");
  // console.log(from);

  // default to avoid landing on empty page
  if (!to.name) {
    if (!auth.currentUser) {
      next(Login);
      return;
    }

    Authstore.dispatch("getClaims").then(
      error => handleClaimsError(next, error),
      result => {
        if (result.member) {
          next({ name: "DashboardMember" });
        } else if (result.owner) {
          next({ name: "DashboardOwner" });
        } else if (result.press) {
          next({ name: "DashboardPress" });
        } else if (result.admin) {
          handleAdminAccess(to, next);
        } else {
          handleClaimsError(next);
        }
      }
    );

    return;
  }

  // full access for delvelopment. To test access control set DEV_FULL_ACCESS to false
  const DEV_FULL_ACCESS = false;

  if (DEV_FULL_ACCESS && !C.PRODUCTION) {
    next();
    return;
  }

  // when user is not logged in: only allow publicly available pages, otherwise redirect to login
  const publicPage = to.matched.every(record => !record.meta.requiresAuth);
  if (!auth.currentUser) {
    if (publicPage) next();
    else next(Login);
    return;
  }

  // when user is logged in, we need to fist find out if it is a member/owner/press/admin
  // SPECIAL CASE: right after logging/registering in, we have set the rout to Dashboard but need to wait for claims to know which dashboard is the right one
  Authstore.dispatch("getClaims").then(
    error => handleClaimsError(next, error),
    result => {
      if (result.member) {
        handleMemberAccess(to, next);
      } else if (result.owner) {
        handleOwnerAccess(to, next);
      } else if (result.press) {
        handlePressAccess(to, next);
      } else if (result.admin) {
        handleAdminAccess(to, next);
      } else {
        handleClaimsError(next);
      }
    }
  );
});

function handleClaimsError(next, error) {
  console.log("handleClaimsError");
  // TODO!!!!!! either getting claims from store caused an error or claims are not set

  if (error) console.log("In router: could not get claims" + error);

  // TODO: make user sign in again?
}

function handleMemberAccess(to, next) {
  if (!C.PRODUCTION) console.log("handleMemberAccess");

  // check email verification and if user should be redirected to dashboard because he is logged in already
  const mailVerifiedRedirection = !auth.currentUser.emailVerified && to.name != "Collection" && to.name != "DashboardMember" && to.name != "ProfileMember"; // only allow few pages when user not yet verified
  const routeMetaDataArray = to.matched;
  const requiresAuth = routeMetaDataArray.some(record => record.meta.requiresAuth);
  const loggedInRedirection = !requiresAuth && to.name != "Collection";
  if (mailVerifiedRedirection || loggedInRedirection) {
    next({ name: "DashboardMember" });
    return;
  }

  // check if requested page is available and grant access, otherwise redirect to dashboard
  const memberPage = routeMetaDataArray.some(record => record.meta.memberAccess);
  if (!memberPage) {
    next({ name: "DashboardMember" });
    return;
  }
  // Access granted
  next();
}
function handleOwnerAccess(to, next) {
  if (!C.PRODUCTION) console.log("handleOwnerAccess");

  // check email verification and if user should be redirected to dashboard because he is logged in already
  // TODO: if owner email not verified (after changing address): block accesses or not???
  // const mailVerifiedRedirection = !auth.currentUser.emailVerified && to.name != "Collection" && to.name != "DashboardOwner" && to.name != "ProfileOwner"; // only allow few pages when user not yet verified
  const mailVerifiedRedirection = false;
  const routeMetaDataArray = to.matched;
  const requiresAuth = routeMetaDataArray.some(record => record.meta.requiresAuth);
  const loggedInRedirection = !requiresAuth && to.name != "Collection";
  if (mailVerifiedRedirection || loggedInRedirection) {
    // console.log("verifiedRedir: " + mailVerifiedRedirection + "  logginRedir: " + loggedInRedirection);
    next({ name: "DashboardOwner" });
    return;
  }

  // check if requested page is available and grant access, otherwise redirect to dashboard
  const ownerPage = routeMetaDataArray.some(record => record.meta.ownerAccess);
  if (!ownerPage) {
    next({ name: "DashboardOwner" });
    return;
  }
  // Access granted
  next();
}
function handlePressAccess(to, next) {
  if (!C.PRODUCTION) console.log("handlePressAccess");


  // check email verification and if user should be redirected to dashboard because he is logged in already
  const mailVerifiedRedirection = !auth.currentUser.emailVerified && to.name != "Collection" && to.name != "DashboardPress" && to.name != "ProfilePress"; // only allow few pages when user not yet verified
  const routeMetaDataArray = to.matched;
  const requiresAuth = routeMetaDataArray.some(record => record.meta.requiresAuth);
  const loggedInRedirection = !requiresAuth && to.name != "Collection";
  if (mailVerifiedRedirection || loggedInRedirection) {
    next({ name: "DashboardPress" });
    return;
  }

  // check if requested page is available and grant access, otherwise redirect to dashboard
  const pressPage = routeMetaDataArray.some(record => record.meta.pressAccess);
  if (!pressPage) {
    next({ name: "DashboardPress" });
    return;
  }
  // Access granted
  next();
}

function handleAdminAccess(to, next) {
  if (!C.PRODUCTION) console.log("user is amdin => no access restriction");

  // avoid that admin lands on empty page
  if (to.path === "" || to.path === "/" || to.name == "Login") {
    next({ name: "DashboardAdmin" });
    return;
  }

  next();
}

export default router;
